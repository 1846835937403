@import "../node_modules/@cnet/ngx-ui/src/lib/styles/styles.scss";
@import "./scss/bootstrap-4-grid/grid.scss";

html {
  overflow-x: hidden !important;
}

body {
  color: #586673;
}

h1 {
  margin-bottom: map-get($spacers, 4);
}

.cnet-badge {
  font-weight: bold;
}

.container {
  margin: 0 auto;
  width: calc(100% - #{map-get($spacers, 6)});
  max-width: 1360px;
}

.cnet-profile-menu-avatar {
  height: 60px;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6rem 1rem;
}

.cnet-profile-menu-avatar:hover {
  cursor: pointer;
  background-color: cnet-color($secondary-palette, 600);
}

.toolbar-mobile-navigation .cnet-profile-menu-avatar:hover {
  cursor: pointer;
  background-color: #f5f6f7;
}

.img-logo:hover {
  cursor: pointer;
}

.sub-header-tab {
  .cnet-tab-header {
    background-color: #fff;
    padding: 0 3rem;
  }
}

.badge-status-card {
  text-transform: capitalize;
  font-size: 1.125rem;
  margin: 0;
}

.badg-test {
  position: relative;
  top: -2px;
  left: 16px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
  width: -moz-fit-content;
  width: fit-content;
  width: 100%;
}

.badge-shape {
  font-size: 1.125rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 6px 30px;
  border-radius: 0 0 0 16px;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 600;

  &:not(.cnet-badge-info) {
    text-shadow: 0px 0px 3px black;
  }
}

.badge-version {
  width: 55px;
}

// noscript

input.modal-closing-trick:checked~div {
  display: none !important;
}

#modal-closing-trick {
  display: none;
}

label[for],
input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #333333;
  background: #444444;
  // background-color:rgba(0, 0, 0, 0.8);
  background-size: cover;
  background-image: url("/assets/images/login-background.jpg");
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.modal:target {
  /** Show lightbox when it is target */
  display: block;
  outline: none;
}

.modal .box {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  min-width: 650px;
  margin: 2% auto;
  padding: 2rem 3rem;
  background-color: #fff;
  box-shadow: 0 20px 24px -2px rgba(0, 0, 0, 0.8);
  border-radius: 0.25rem;
}

.modal .title {
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 2rem;
  color: #586673;
}

.modal .content {
  display: block;
  padding: 10px 0 0 0px;
  color: var(--cnet--700, #616161);
  margin: 12px;
}

.button {
  font-family: "Lucida Grande", "Lucida Sans Unicode", Tahoma, Sans-Serif;
  display: inline-block;
  outline: 0;
  line-height: 1.4;
  background: linear-gradient(#4d4d4d, #2f2f2f);
  border-radius: 5px;
  border: 1px solid #000;
  color: #fff !important;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none !important;
  cursor: pointer;
  text-align: center;
  padding: 10px 14px;
  margin: 12px;
}

.toaster-header {
  font-size: $font-size-large;
}

cnet-field-label:first-letter {
  text-transform: capitalize;
}
.break-word{
  word-break: break-word;
}



.small-gap {
  gap: 0.5rem;
}

.extra-small-gap{
  gap: 0.1rem;
}

.gap-1 {
  gap: 1rem;
}

.cnet-sidenav-item {
  margin-right: 2rem;
  min-width: 112px;
}

.cnet-sidenav-item:hover {
  cursor: pointer;
  background: #f4f4f4;

  button {
    display: block !important;
  }
}

.cnet-sidenav-item.cnet-sidenav-item-activated {
  border-left: 4px solid var(--cnet-primary-500, #82c14f);
  background: #eaeaea;
}

.sidenav-list {
  padding: 1rem;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
}

.cnet-page-sidenav {
  width: 350px;
}

.cnet-page-sidenav-content {
  padding-left: 1rem;
}

.cnet-page-side-nav-container {
  margin: 0 8rem;
}

.new_btn.cnet-sidenav-item.cnet-sidenav-item-activated.cnet-color-primary {
  color: white;
}

:host {
  ::ng-deep {
    cnet-page-sidenav {
      height: fit-content;
    }
  }
}

.sections-list {
  width: fit-content;
  background: white;
  padding: 1rem;
}

.cnet-drawer {
  width: fit-content;
}

.section {
  width: 100%;
  padding: 0.5rem;
  min-height: 46px;
}

@media (max-width: 1280px) {
  .cnet-page-side-nav-container {
    margin: 0 2rem;
  }
}

.first-letter-capitalization::first-letter {
  text-transform: capitalize;
}

th::first-letter {
  text-transform: capitalize;
}

.cnet-field-label::first-letter {
  text-transform: capitalize;
}

.sidenav {
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: .25rem;
}

.mat-drawer-side {
  border: none !important
}

.sidenav-responsive-button {
  width: 0px;
  /* height: 100%; */
  align-self: stretch;
  border-right: 2px solid #ccc;
  margin-right: 1rem;
  z-index: 2;
  // padding: 0.6rem 1rem;
  cursor: pointer;
  background: #fff;

  cnet-icon {
    position: relative;
    top: 0;
    left: -15px;
    border-radius: 50px;
    width: 1rem;
    height: 1rem;
    padding: 1rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

// sizing

.w-40 {
  width: 40%;
}

@media (max-width: 992px) {
  .w-40 {
    width: 100%;
  }
}

.grid {
  display: grid;
  gap: 1rem;
  margin-bottom: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 262px), 1fr));
}
